import React from 'react';
import { useLocalization } from '../../providers/LocaleProvider';
import SubmitInput, { FormStateType } from '../SubmitInput';
import { EmailSubmitButton } from './EmailSubmitButton';
import useNewsletterSubscription from './hooks';
import { FormMessage } from './utils/validation';
var NewsletterSignupInput = function (_a) {
    var _b = _a.initialState, initialState = _b === void 0 ? FormStateType.Default : _b, _c = _a.initialMessage, initialMessage = _c === void 0 ? FormMessage.None : _c, initialIsLoading = _a.initialIsLoading, tracking = _a.tracking, promoClickOnSubmit = _a.promoClickOnSubmit, buttonKind = _a.buttonKind, newsletterType = _a.newsletterType;
    var _d = useNewsletterSubscription({
        onSubmit: promoClickOnSubmit,
        initialState: initialState,
        initialMessage: initialMessage,
        initialIsLoading: initialIsLoading,
        tracking: tracking,
        newsletterType: newsletterType,
    }), value = _d.value, isLoading = _d.isLoading, message = _d.message, state = _d.state, onChange = _d.onChange, onSubmit = _d.onSubmit;
    var t = useLocalization().t;
    return (React.createElement(SubmitInput, { inputLabel: t('h24_newsletter_input_placeholder'), inputType: "email", inputName: "email", inputRequired: true, state: state, value: value, onChange: onChange, onSubmit: onSubmit, isLoading: isLoading, buttonLabel: t('h24_newsletter_submit_button'), buttonKind: buttonKind, buttonContent: React.createElement(EmailSubmitButton, null, t('h24_newsletter_submit_button')), buttonDisabled: state === FormStateType.Success, message: message && t(message) }));
};
export default React.memo(NewsletterSignupInput);
